import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["header", "body", "chevron"];
  static values = {
    persistOpen: String,
  };

  toggle(event) {
    const header = event.currentTarget;
    const body = this.bodyTargets.find(
      (el) => el.id === header.getAttribute("aria-controls"),
    );
    const chevron = header.querySelector(
      '[data-picks-accordion-target="chevron"]',
    );

    if (header.getAttribute("aria-expanded") === "true") {
      this.closePanel(header, body, chevron);
    } else {
      this.openPanel(header, body, chevron);
      this.updateUrlParam(header);
    }
  }

  updateUrlParam(header) {
    const persistOpen = header.getAttribute("data-persist-open");
    if (persistOpen) {
      const url = new URL(window.location);
      url.searchParams.set("open", persistOpen);
      window.history.replaceState({ turbo: true, open: persistOpen }, "", url);
    }
  }

  closeAllPanels() {
    this.headerTargets.forEach((header, index) => {
      const body = this.bodyTargets[index];
      const chevron = header.querySelector(
        '[data-picks-accordion-target="chevron"]',
      );
      this.closePanel(header, body, chevron);
    });
  }

  openPanel(header, body, chevron) {
    header.setAttribute("aria-expanded", "true");
    body.classList.remove("hidden");
    chevron.classList.remove("rotate-180");
  }

  closePanel(header, body, chevron) {
    header.setAttribute("aria-expanded", "false");
    body.classList.add("hidden");
    chevron.classList.add("rotate-180");
  }
}
