import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["claimItem"];

  connect() {
    this.originalOrder = this.getClaimIds();
    this.registerElements();
    this.updateUI();
    this.updateSubmitButtonState();
  }

  registerElements() {
    // Find the submit button once
    this.submitButton = this.element.querySelector('button[type="submit"]');
  }

  moveUp(event) {
    const claimItem = event.currentTarget.closest(
      '[data-waiver-reorder-target="claimItem"]',
    );
    const previousItem = claimItem.previousElementSibling;

    if (
      previousItem &&
      previousItem.hasAttribute("data-waiver-reorder-target")
    ) {
      claimItem.parentNode.insertBefore(claimItem, previousItem);
      this.updateUI();
      this.updateSubmitButtonState();
    }
  }

  moveDown(event) {
    const claimItem = event.currentTarget.closest(
      '[data-waiver-reorder-target="claimItem"]',
    );
    const nextItem = claimItem.nextElementSibling;

    if (nextItem && nextItem.hasAttribute("data-waiver-reorder-target")) {
      claimItem.parentNode.insertBefore(nextItem, claimItem);
      this.updateUI();
      this.updateSubmitButtonState();
    }
  }

  updateUI() {
    // Track global index for visual display
    let globalIndex = 1;

    // Update UI elements
    this.claimItemTargets.forEach((item) => {
      // Update the visual order number
      const orderElement = item.querySelector(".order-number");
      if (orderElement) {
        orderElement.textContent = globalIndex++;
      }

      // Handle up/down buttons within each date group
      const isFirstInGroup =
        !item.previousElementSibling ||
        !item.previousElementSibling.hasAttribute("data-waiver-reorder-target");
      const isLastInGroup =
        !item.nextElementSibling ||
        !item.nextElementSibling.hasAttribute("data-waiver-reorder-target");

      const upButton = item.querySelector(".move-up-button");
      const downButton = item.querySelector(".move-down-button");

      if (upButton) {
        upButton.disabled = isFirstInGroup;
        upButton.classList.toggle("cursor-not-allowed", isFirstInGroup);
        upButton.classList.toggle("opacity-50", isFirstInGroup);

        // Enable hover effects conditionally
        upButton.classList.toggle("hover:bg-gray-100", !isFirstInGroup);
        upButton.classList.toggle("dark:hover:bg-gray-800", !isFirstInGroup);
      }

      if (downButton) {
        downButton.disabled = isLastInGroup;
        downButton.classList.toggle("cursor-not-allowed", isLastInGroup);
        downButton.classList.toggle("opacity-50", isLastInGroup);

        // Enable hover effects conditionally
        downButton.classList.toggle("hover:bg-gray-100", !isLastInGroup);
        downButton.classList.toggle("dark:hover:bg-gray-800", !isLastInGroup);
      }
    });
  }

  updateSubmitButtonState() {
    const currentOrder = this.getClaimIds();
    const hasChanged = !this.areArraysEqual(currentOrder, this.originalOrder);

    if (this.submitButton) {
      this.submitButton.disabled = !hasChanged;
      this.submitButton.classList.toggle("opacity-50", !hasChanged);
      this.submitButton.classList.toggle("cursor-not-allowed", !hasChanged);
    }
  }

  getClaimIds() {
    return this.claimItemTargets.map((item) => item.dataset.claimId);
  }

  areArraysEqual(array1, array2) {
    if (array1.length !== array2.length) return false;
    return array1.every((value, index) => value === array2[index]);
  }

  submitForm(event) {
    // Get the ordered IDs from the current DOM state
    const orderedClaimIds = this.getClaimIds();

    // Create a hidden input with the ordered IDs
    const form = event.currentTarget;

    // Remove any existing hidden input we might have added before
    const existingInput = form.querySelector('input[name="orderedClaimIds"]');
    if (existingInput) {
      existingInput.remove();
    }

    // Create and append the new hidden input with the JSON data
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = "orderedClaimIds";
    input.value = JSON.stringify(orderedClaimIds);
    form.appendChild(input);
  }
}
